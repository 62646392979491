<template>
  <div class="posterDetail-container">
    <header class="poster-header">
      <van-nav-bar
          title="海报详情"
          left-arrow
          @click-left="handleBlack"
          safe-area-inset-top
      >
        <template #right>
          <icon-svg
              iconClass="iconshare-2"
              @click="handleShare"
              style="height: 24px; width: 24px; color: '#333333'"
          />
        </template>
      </van-nav-bar>
    </header>
    <article class="poster-details-article">
      <div class="poster-save-canvas">
<!--        <div class="poster-details" id="detailssaveposter" v-if="showImg" ref="imageWrapper">-->
<!--&lt;!&ndash;        <div class="poster-details" id="detailssaveposter" ref="imageWrapper">&ndash;&gt;-->
<!--          <img :src="posterPic" crossOrigin="anonymous"/>-->
<!--          <div class="text-qrcode">-->
<!--            <div class="left-text">-->
<!--              <p>{{ posterName }}</p>-->
<!--              <p>分享给朋友</p>-->
<!--            </div>-->
<!--            <div class="qrCodeUrl-all">-->
<!--              <div class="qrCodeUrl-cls">-->
<!--                <img v-if="qrCodeUrl" :src="qrCodeUrl"/>-->
<!--              </div>-->
<!--              <p>长按识别</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="poster-details" id="detailssaveposter" v-if="true" ref="imageWrapper">
          <div class="poster-img">
            <img :src="posterPic" class="main-img" crossOrigin="anonymous"/>
            <img v-if="showMerchantLogo" :src="merchantLogoUrl" class="logo-img" >
          </div>
          <div class="text-qrcode">
            <div class="user-info-tag">
              <div class="user-info">
                <img :src="headImgUrl">
                <div class="left-text">
                  <p>{{ userForm.name }}</p>
                  <p>{{ userForm.merchantName }}</p>
                </div>
              </div>
              <div class="user-tag">
                <span class="user-tag-item" v-for="(it,index) in userTags" :key="index">{{ it }}</span>
              </div>
            </div>
            <div class="qrCodeUrl-all">
              <div class="qrCodeUrl-cls">
                <img v-if="qrCodeUrl" :src="qrCodeUrl"/>
              </div>
              <p>立即扫码</p>
              <p>了解更多内容</p>
            </div>
          </div>
        </div>
        <div style="z-index: -100000;position: absolute;top: -1000px">
          <img class="card-img" :src="imgSrc" ref="cardImgRef"/>
        </div>
      </div>
      <div class="handle-poster">
        <span @click="handleReplaceQrcode">更改海报信息</span>
        <span @click="toSavePic">保存图片</span>
      </div>
    </article>
    <footer class="poster-footer">
      <van-button type="primary" block color="#FF6E01" @click="handleShare">分享</van-button>
    </footer>
    <!-- 弹出分享框 -->
    <div class="share_box">
      <van-share-sheet
          v-model:show="showShare"
          title="分享"
          :options="options"
          cancel-text="取消"
          @select="onSelect"
          safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>

<!--    更换地址-->
    <van-overlay :show="showReplaceQrcode" @click="show = false">
      <div class="qr-wrapper" @click.stop>
        <div class="replace-qrcode">
          <div style="color: #FFFFFF;text-align: right">
            <icon-svg
              iconClass="iconx"
              @click="handleCancelEditor"
              style="height: 24px; width: 24px; color: '#333333'"
            />
          </div>
          <div class="block" >
            <p>海报名称：</p>
            <van-field v-model="posterName" label="" />
            <p>可替换地址：</p>
            <van-field
                v-model="jumpUrl"
                clearable
                rows="1"
                type="textarea"
                placeholder="请输入地址"
            />
            <div class="moveto">
              <span @click="handleCopyUrl('article')">文章</span>
              <span @click="handleCopyUrl('cases')">设计</span>
              <span @click="handleCopyUrl('video')">短视频</span>
              <span @click="handleCopyUrl('buildSite')">工地</span>
<!--              <span @click="handleCopyUrl('card')">名片</span>-->
            </div>
            <div class="logo">
              <p>显示公司logo：</p><van-switch v-model="showMerchantLogo" size="18px" />
            </div>
          </div>
          <van-button color="#FF6E01" @click="handleComfirm">确定</van-button>
        </div>
        </div>
    </van-overlay>
  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {getPosterAppDetails, updatePosterApp, reprintPoster} from "../../api/posterCenter"
import {nextTick, reactive, ref, toRefs, watch} from "vue";
// import { sharePosterToMiniProgram } from "@/utils/commonUtil";
import html2canvas from "html2canvas";
import {getUserCardByPoster} from "@/api/user/userCard"
import { uploadCoverPic} from "@/api/file/index";
import {flutterShowToast} from "@/utils/appChannel";
import { loadImages } from "@/utils/commonUtil";
import {Toast} from "vant";
export default {
  name: "posterDetail",
    setup(){
      const route = useRoute()
      const router = useRouter()
      const imageWrapper = ref(null);
      const cardImgRef = ref(null);
      const state = reactive({
        userForm:{},
        userTags:[],
        headImgUrl:'',
        imgUrl: process.env.VUE_APP_WATCH_FILE_API,
        isIphonex:false,
        posterName:'',
        posterPic:'',
        merchantLogoUrl:'',
        showMerchantLogo:true,
        defaultImg:require("@/assets/images/poster-default.jpg"),
        defaultMiniImg:require("@/assets/images/poster-default-mini.jpg"),
        showImg:true,
        imgSrc: "",
        showOverlay: false,
        showPoster:false,
        qrCodeUrl:'',
        jumpUrl:'',
        showReplaceQrcode:false,
        options: [
          { name: "微信", icon: require("@/assets/icons/weChat2.png") },
          { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        ],
        showShare:false,
        posterId:route.query.posterId,
        baseUrl:`${process.env.VUE_APP_WATCH_FILE_API}/`,
        posterDetails:{},
        sharePosterImg:'',
      })
      watch(()=>state.showShare,(newStr,oldStr)=>{
        console.log("newStr========>",newStr)
        console.log("oldStr===========>",oldStr)
        if(newStr === false){
          state.showOverlay = false
        }
      })
      const handleBlack = () =>{
        router.push({
          path:'/posterVisitors',
        })
      }
      const loadPoster = (e)=>{
        console.log("加载图片",e)
        savePoster()
        setTimeout(()=>{
          Toast.clear()
        })
      }
      const getPosterDetails = async () =>{
        let w = window.screen.width * window.devicePixelRatio;
        let h = window.screen.height * window.devicePixelRatio;
        // IDiphonex = !!(w == 1125 && h == 2436);
        if (w == 1125 && h == 2436){
          state.isIphonex = true
        }
        Toast.loading({
          message: '海报加载中...',
          forbidClick: true,
          duration:0
        });
      let res = await getUserCardByPoster()
          if (res){
            if (res.headImgUrl){
              state.headImgUrl = state.imgUrl+'/'+res.headImgUrl
            }else {
              state.headImgUrl = require("@/assets/poster_headerImg_default.png")
            }
            state.userForm = res
            state.userTags = res.impressionLabel.split(',')
            if (state.userTags.length>4){
              state.userTags = state.userTags.slice(0,4)
            }
          }

        getPosterAppDetails(state.posterId).then((res)=>{
          state.posterDetails = res
          state.posterPic = res.posterImgUrl?state.baseUrl+res.posterImgUrl:state.defaultImg
          state.merchantLogoUrl = state.baseUrl+res.merchantLogoUrl
          state.posterName = res.posterName
          if (res.showMerchantLogo==1){
            state.showMerchantLogo = true
          }else {
            state.showMerchantLogo = false
          }
          if(res.qrCodeUrl){
            state.qrCodeUrl = res.qrCodeUrl
          }
          let arr = []
          arr.push(state.posterPic)
          arr.push(state.qrCodeUrl)
          arr.push(state.headImgUrl)
          if (state.showMerchantLogo){
            arr.push(state.merchantLogoUrl)
          }
          loadImages(arr,loadPoster)
          // loadedImg()
          // setTimeout(()=>{
          //   savePoster()
          // },300)
        })

      }
      getPosterDetails()
      //微信分享
      const onSelect = async (index) => {
        Toast.loading({
          message: '正在\n打开微信...',
          forbidClick: true,
          duration:0,
          className:'posterToast',
        });
        let posterFile = await dataURLtoFile(state.imgSrc,'png')
        if(posterFile){
          let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", posterFile); //接口需要传的参数
          formData.append("busiId", state.posterId); //接口需要传的参数
          formData.append("busiCat", "wxShare"); //接口需要传的参数
          formData.append("groupId", "posterImg"); //接口需要传的参数
          uploadCoverPic(formData)
              .then((data) => {
                let item = {
                  url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
                  fileId: data.fileId,
                };
                console.log(item)
                // Toast.clear()
                let shareType;
                let shareImg = state.posterDetails.posterImgUrl?`${state.baseUrl}${state.posterDetails.smallPosterImgUrl}`:'https://wca.ujiaku.com/upload/wx/poster-default-mini.jpg';//缩略图
                let content = `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`;//图片地址
                if(index.name=== "微信"){
                  shareType = "shareImageToWeiXin"
                }else  if(index.name === "朋友圈"){
                  shareType = "shareImageToTimeLine"
                }
                let params = {
                  shareType:shareType,
                  shareImg:shareImg,
                  urlEntity:{
                    content:content
                  }

                }
                console.log(params);
                flutterShowToast(params);
                reprintPoster(state.posterDetails.posterId)
                state.showOverlay  = false
                setTimeout(()=>{
                  Toast.clear()
                },1500)
              })
              .catch((err) => {
                console.log(err);
              });
        }
      };
      //保存到手机
      const toSavePic = () => {
        let params = {
          shareType:"saveBase64Image",
          desc:state.imgSrc
        }
        flutterShowToast(params)
        console.log(params)
      }
      //打开分享
      const handleShare = () =>{
        // await savePoster()
        if(state.qrCodeUrl&&state.qrCodeUrl.indexOf("data:image/jpg;base64,null")===-1){
          console.log("打开分享",state.showShare)
          state.showShare = true
        }else {
          Toast("暂无跳转地址，请先更换海报地址")
        }
      }
      //更换二维码
      const handleReplaceQrcode = () =>{
        state.jumpUrl = state.posterDetails.jumpUrl
        state.showReplaceQrcode = true
      }
      //取消
      const handleCancelEditor = () =>{
        state.showReplaceQrcode = false
      }
      //确认
      const handleComfirm = () =>{
        let  params = {
          posterId:state.posterDetails.posterId,
          posterName:state.posterName,
          jumpUrl:state.jumpUrl
        }
        if (state.showMerchantLogo){
          params.showMerchantLogo = 1
        }else {
          params.showMerchantLogo = 0
        }
        updatePosterApp(params).then((res)=>{
          if(res){
            state.posterId = res
            state.showReplaceQrcode = false
            state.showImg = true
            getPosterDetails()
          }
        })
      }
      const  isAndroidOrIOS=()=>{
        const u = navigator.userAgent;
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
          return "android"
        }
        if (isiOS) {
          return "ios"
        }

        return false
      }
      const savePoster = () =>{
        // console.log("保存")
        // state.showOverlay = true;
        // html2canvasChange()
        nextTick(() => {
          console.log("imageWrapper", imageWrapper.value);
          // var canvas2 = document.createElement("canvas");
          let _canvas = imageWrapper.value;
          const box = window.getComputedStyle(_canvas);
          // DOM 节点计算后宽高
          const width = parseInt(box.width, 10);
          const height = parseInt(box.height, 10);
          // 获取像素比
          const scaleBy = DPR();
          console.log("屏幕-------",window.devicePixelRatio,"分辨率---")
          // 创建自定义 canvas 元素
          const canvas = document.createElement("canvas");
          // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
          canvas.width = width * scaleBy;
          canvas.height = height * scaleBy;
          // 设定 canvas css宽高为 DOM 节点宽高
          canvas.style.width = `${width}px`;
          canvas.style.height = `${height}px`;
          // canvas.style.width = `375px`;
          // canvas.style.height = `${height}px`;
          // canvas.getContext("2d").scale(scaleBy,scaleBy); //获取context,设置scal

          // const context = canvas.getContext('2d');
          // context.scale(scaleBy, scaleBy);
          // let rect = imageWrapper.value.getBoundingClientRect(); //获取元素相对于视察的偏移量
          // context.translate(-rect.left, -rect.top); //设置context位置，值为相对于视窗的偏移量负值，让图片复位
          // 将所有绘制内容放大像素比倍
          console.log("高度========",document.getElementById('detailssaveposter').scrollHeight,)
          let options={
            // dpi:350,
            scale: window.devicePixelRatio || 1,
            // scale: 2,
            useCORS: true,
            background: "#ffffff",
            // width:width, //dom 原始宽度
            // height:height, //dom 原始高度
            windowHeight: document.getElementById('detailssaveposter').scrollHeight,
            canvas,
          }
          if(isAndroidOrIOS()==='ios'&&!state.isIphonex){
            options.y = 254
          }
          html2canvas(_canvas, options).then((canvas) => {
            let context = canvas.getContext("2d")
            context.mozImageSmoothingEnabled = false;
            context.webkitImageSmoothingEnabled = false;
            context.msImageSmoothingEnabled = false;
            context.imageSmoothingEnabled = false;
            let dataURL = canvas.toDataURL("image/png");
            state.showImg = false
            nextTick(() => {
              cardImgRef.value.style.width = width + "px";
              cardImgRef.value.style.height = height + "px";
              state.imgSrc = dataURL;
            });
          });
        });
      }
      //画图
      const html2canvasChange = () =>{

      }

      //文件上传
      const uploadImg = (file)=>{
        let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        formData.append("file", file); //接口需要传的参数
        formData.append("busiId", state.posterId); //接口需要传的参数
        formData.append("busiCat", "wxShare"); //接口需要传的参数
        formData.append("groupId", "posterImg"); //接口需要传的参数
        uploadCoverPic(formData)
            .then((data) => {
              let item = {
                url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
                fileId: data.fileId,
              };
              state.sharePosterImg = item.url
            })
            .catch((err) => {
              console.log(err);
            });
      }
      //base64转文件
      const dataURLtoFile=(dataurl, filename)=> {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
      }

      const handleChangImg = () =>{
      }

      const DPR = () => {
        if (window.devicePixelRatio && window.devicePixelRatio > 1) {
          return window.devicePixelRatio;
        }
        return 1;
      };
      const  handleCancel = () =>{
        state.showOverlay = false
      }
      const handleCopyUrl = (val)=>{
        switch (val){
          case 'article':
            router.push({
              path:'/articleVisitors',
              name:'articleVisitors',
              params:{
                fromPoster:true
              }
            })
            break;
          case 'cases':
            router.push({
              path:'/caseVisitors',
              name:'caseIndex',
              params:{
                fromPoster:true,
              }
            })
            break;
          case 'video':
            router.push({
              path:'/videoVisitors',
              name:'video',
              params:{
                fromPoster:true
              }
            })
            break;
          case 'buildSite':
            router.push({
              path:'/buildVisitors',
              name:'buildVisitors',
              params:{
                fromPoster:true,
              }
            })
            break;
          case 'card':
            router.push({
              path:`/userCard`,
              name:'userCard',
              params:{
                fromPoster:true
              },
              query:{
                hideTitleBar:99,
              }
            })
            break;
          default:
            break;
        }
      }
      return{
        ...toRefs(state),
        handleCopyUrl,
        toSavePic,
        handleCancel,
        html2canvasChange,
        uploadImg,
        handleChangImg,
        dataURLtoFile,
        cardImgRef,
        DPR,
        imageWrapper,
        savePoster,
        handleComfirm,
        handleCancelEditor,
        handleReplaceQrcode,
        handleShare,
        onSelect,
        handleBlack,
        getPosterDetails
      }
    }
  }
</script>

<style lang="scss">
.posterDetail-container{
  .poster-header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
    .articleType-all {
      .van-tabs__wrap {
        border-bottom: 0.5px solid #f1f3f6;
      }
      .van-tab {
        color: #666666;
        font-size: 12px;
        font-weight: 600;
        flex: auto;
        //   border-bottom: 1px solid #cccccc;
      }
      .van-tab--active {
        color: #323233;
        font-size: 12px;
        font-weight: 600;
        width: auto;
      }
    }
  }
  .poster-footer{
    .van-button--block{
      border-radius: 4px;
    }
  }
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
  .qr-wrapper{
    //display: flex;
    //align-items: center;
    //justify-content: center;
    height: 100%;
    .replace-qrcode{
      position: absolute;
      left: 15px;
      top: 100px;
      .block {
        width: 343px;
        height: 280px;
        border-radius: 8px;
        background-color: #fff;
        padding: 11px 16px;
        box-sizing: border-box;
        text-align: left;
        >p{
          margin: 0;
          padding: 0;
          font-weight: 600;
          font-size: 16px;
        }
        .logo{
          //margin-top: 8px;
          //margin-bottom: 20px;
          margin: 10px 0;
          padding-right: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          >p{
            margin: 0;
            padding: 0;
            font-weight: 600;
            font-size: 16px;
          }
        }
        .moveto{
          display: flex;
          font-size: 14px;
          color: #289FFF;
          padding: 5px;
          justify-content: space-around;
        }
      }
    }
    .van-button--normal{
      width: 72px;
      height: 28px;
      border-radius: 4px;
      margin-top: 30px;
      .van-button__text{
        font-size: 12px;
      }
    }
  }
  .img-wrapper{
    overflow-x: hidden;
    margin-top: 20%;
    height: 80%;
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    //img{
    //  margin-top: 20%;
    //}
    p{
      font-size: 14px;
      color: #FFFFFF;
    }
    .card-img{
      //display: block;
    }
  }
}
</style>
<style lang="scss" scoped>
.posterDetail-container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  .poster-header {
    font-size: 18px;
  }
  .poster-details-article{
    //padding-top: 10%;
    background-color: #E5E5E5;
    flex: 1;
    overflow-x: hidden;
    .poster-save-canvas{
      //width: 300px;
      //height: 420px;
      width: 100%;
      overflow-x: hidden;
      .poster-details{
        //opacity:0;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        //width: 375px;
        width: 100%;
        .poster-img{
          //width: 375px;
          width: 100%;
          position: relative;
          .main-img{
            width: 100%;
            display: block;
          }
          .logo-img{
            position: absolute;
            width: 60px;
            top: 18px;
            left: 50%;
            transform: translate(-50%,0%);
          }
        }
        >img{
          //width: 375px;
          width: 100%;

          display: block;
        }
        //.text-qrcode{
        //  background-color: #ffff;
        //  display: flex;
        //  justify-content: space-between;
        //  padding: 18px;
        //  .left-text{
        //    text-align: left;
        //    line-height: 26px;
        //    p {
        //      margin: 0;
        //      padding: 0;
        //    }
        //    p:nth-child(1){
        //      font-size: 18px;
        //      font-weight: 600;
        //      line-height: 26px;
        //      color: #333333;
        //    }
        //    p:nth-child(2){
        //      font-size: 11px;
        //      font-weight: 400;
        //      color: #999999;
        //      //line-height: 20px;
        //    }
        //  }
        //  .qrCodeUrl-all{
        //    position: relative;
        //    text-align: center;
        //    height: 50px;
        //    margin-right: 10px;
        //    .qrCodeUrl-cls{
        //      width: 70px;
        //      height: 70px;
        //      background-color: #FFFFFF;
        //      border-radius: 35px;
        //      position: relative;
        //      top: -40px;
        //      text-align: center;
        //      img{
        //        width: 60px;
        //        border-radius: 30px;
        //        position: relative;
        //        margin-top: 50%;
        //        transform: translateY(-50%);
        //      }
        //    }
        //    >p{
        //      position: relative;
        //      font-size: 11px;
        //      font-weight: 400px;
        //      color: #999999;
        //      top: -50px;
        //    }
        //  }
        //
        //}
        .text-qrcode{
          background-color: #ffff;
          display: flex;
          justify-content: space-between;
          padding: 10px;
          flex: 1;
          .user-info-tag{
            .user-info{
              display: flex;
              align-items: center;
              img{
                border-radius: 24px;
                width: 48px;
                height: 48px;
                object-fit: cover;
                flex-shrink: 0;
              }
              .left-text{
                text-align: left;
                //flex: 1;
                margin-left: 4px;
                p {
                  margin: 0;
                  padding: 0;
                }
                p:nth-child(1){
                  font-size: 22px;
                  font-weight: 400;
                  line-height: 18px;
                  color: #333333;
                }
                p:nth-child(2){
                  margin-top: 5px;
                  font-size: 14px;
                  color: #333333;
                  //line-height: 20px;
                }
              }
            }
            .user-tag{
              display: flex;
              flex-wrap: wrap;
              .user-tag-item{
                margin-top: 5px;
                margin-right: 8px;
                //padding-left: 5px;
                padding:4px 8px;
                color: #289FFF;
                background-color: #E6ECFF;
                font-size: 12px;
                border-radius: 4px;
              }
            }
          }


          .qrCodeUrl-all{
            //position: relative;
            //text-align: center;
            //height: 60px;
            border-left: 1px solid #F1F3F6;
            padding-left: 18px;
            margin-left: 38px;
            flex-shrink:0;
            .qrCodeUrl-cls{
              //width: 60px;
              //height: 60px;
              background-color: #FFFFFF;
              border-radius: 44px;
              //position: relative;
              //top: -33px;
              //text-align: center;
              img{
                width: 88px;
                //border-radius: 25px;
                //position: relative;
                //margin-top: 50%;
                //transform: translateY(-50%);
              }
            }
            >p{
              //position: relative;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              margin: 0;
              //top: -40px;
            }
          }
        }
      }
      .card-img{
        display: block;
        width: 300px;
      }
    }

    .handle-poster{
      font-size: 14px;
      font-weight: 500;
      color: #FC9834;
      display: flex;
      justify-content: space-evenly;
      span{
        margin-top: 16px;
        margin-bottom: 26px;
      }
    }
  }
  .poster-footer{
    padding: 16px;
  }
}
</style>
